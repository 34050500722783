import axios from "axios";

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function isDev() {
    return development;
}

let backend = 'https://api.skyhighcleaning.co.uk/'

if (isDev()) {
    backend = 'http://127.0.0.1:8000/'
}


export const Axios = axios.create({
    baseURL: backend,
    headers: {
        "Content-Type": "application/json",
    },
});

export function ValidateDiscountCode(discount) {
    return new Promise((resolve, reject) => {
        return Axios({
            url: backend + 'bookings/validate-discount/' + discount,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetFranchiseByName(name) {
    return new Promise((resolve, reject) => {
        return Axios({
            url: backend + 'franchise/booking-form/location/' + name,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function SubmitLeadForm(lead: Lead, service, initial: boolean) {
    return new Promise((resolve, reject) => {
        return Axios({
            url: backend + 'bookings/lead-form/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                lead: lead,
                service: service,
                initial: initial
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetServicePrices(postcode) {
    return new Promise((resolve, reject) => {
        return Axios({
            url: backend + 'bookings/service-prices/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                postcode: postcode,
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function SubmitBookingForm(lead: Lead, services, booking_time, discountCode) {
    return new Promise((resolve, reject) => {
        return Axios({
            url: backend + 'bookings/booking-form/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                lead: lead,
                services: services,
                booking_time: booking_time,
                discount_code: discountCode,
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}


export function GetAddressFromPostcode(postcode: String) {
    return new Promise((resolve, reject) => {
        return Axios({
            url: 'https://api.getAddress.io/autocomplete/' + postcode + '?api-key=QkB-1wusi0mtOsOlYU2pMQ43750 ',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}