import * as React from "react";
import {forwardRef, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import NavigationBar from "./NavigationBar";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormHelperText,
    FormLabel,
    Input,
    Select,
    Sheet,
    Tooltip,
    Typography
} from "@mui/joy";
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import {
    CalendarMonth,
    Email,
    EventAvailable,
    Gite,
    Home,
    InfoOutlined,
    Person,
    Phone,
    Roofing,
    Window
} from "@mui/icons-material";
import Option from "@mui/joy/Option";
import badges from "./images/badgets-horizontal.png";
import {GetAddressFromPostcode, GetServicePrices, SubmitBookingForm, ValidateDiscountCode} from "../Api";
import trust_pilot from './images/trust_pilot.jpg';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';

interface AddressResponse {
    suggestions: Address[];
}

interface Address {
    address: string;
}

function formatDate(dateString: string): string {
    const [day, month, year] = dateString.split('/').map(Number);
    const inputDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const timeDifference = inputDate.getTime() - currentDate.getTime();
    const daysAway = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const weeksAway = Math.floor(daysAway / 7);
    const monthsAway = Math.floor(daysAway / 30);
    let daysAwayText = '';

    if (monthsAway >= 1) {
        daysAwayText = monthsAway === 1 ? '1 month away' : `${monthsAway} months away`;
    } else if (weeksAway >= 1) {
        daysAwayText = weeksAway === 1 ? '1 week away' : `${weeksAway} weeks away`;
    } else {
        daysAwayText = daysAway === 1 ? '1 day away' : `${daysAway} days away`;
    }

    return `${dateString} (${daysAwayText})`;
}

const getNextDate = () => {
    const today = new Date();
    const futureDate = new Date(today.setDate(today.getDate() + 7));
    const dayOfWeek = futureDate.getDay();

    const daysToAdd = (dayOfWeek === 0) ? 1 : (dayOfWeek === 6) ? 2 : (8 - dayOfWeek);

    const nextMonday = new Date(futureDate);
    nextMonday.setDate(futureDate.getDate() + daysToAdd);

    return nextMonday;
};

function formatBookedInDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

function formatAsGBP(amount: number): string {
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
}

export default function Booking() {
    const location = useLocation();
    const [booked, setBooked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [cost, setCost] = useState<number>(0);
    const [lead, setLead] = useState<Lead>({
        address: "",
        eMail: "",
        houseType: "Detached",
        name: "",
        phone: "",
        postcode: ""
    });
    const [noLead] = useState<boolean>(lead.name === '');
    const [servicePrices, setServicePrices] = useState<ServicePrice[]>()
    const [activeServicePrice, setActiveServicePrice] = useState<ServicePrice>(getServicePrice())
    const [selectedService, setSelectedService] = useState<string>("gutter clean")
    const [discountCode, setDiscountCode] = useState<string>("")
    const [discount, setDiscount] = useState<number>(-1)
    const [services, setServices] = useState<Service[]>([])

    const [addressList, setAddressList] = useState<Address[]>([]);
    const [validPostcode, setValidPostcode] = useState<boolean>(true);
    const [debouncedPostcode, setDebouncedPostcode] = useState(lead.postcode);
    const [loadingBookingsPage, setLoadingsBookingsPage] = useState(false);
    const [bookedIn, setBookedIn] = useState<Date>(getNextDate())
    const navigate = useNavigate();

    const isWeekdayAndInPast = date => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        const day = date.getDay();
        const isWeekday = day !== 0 && day !== 6;
        const isInPast = date > today;

        return isWeekday && isInPast;
    };

    const DateCustomInput = forwardRef(
        ({value, onClick}, ref) => (
            <Input className="custom-date-input"
                   sx={{width: '100%', background: 'white', textAlign: 'center'}}
                   value={formatDate(value)}
                   onClick={onClick}></Input>
        ),
    );

    useEffect(() => {
        if (servicePrices == undefined) {
            return
        }
        let totalCost = 0
        services.forEach((service) => {
            switch (service.name.toLowerCase()) {
                case "window clean":
                    totalCost += Number(activeServicePrice?.window_clean_price)
                    break
                case "gutter clean":
                    totalCost += Number(activeServicePrice?.gutter_clean_price)
                    break
                case "conservatory gutter clean":
                    totalCost += Number(activeServicePrice?.conservatory_gutter_clean_price)
                    break

            }
        })
        setCost(totalCost)
    }, [services, lead.houseType, servicePrices, activeServicePrice])


    useEffect(() => {
        let newServicePrice = getServicePrice()
        if (newServicePrice) {
            console.log(newServicePrice)
            setActiveServicePrice(newServicePrice)
        }

    }, [lead.houseType, servicePrices])

    function getServicePriceByService(name) {
        switch (name.toLowerCase()) {
            case "window clean":
                return activeServicePrice?.window_clean_price
            case "gutter clean":
                return activeServicePrice?.gutter_clean_price
            case "conservatory gutter clean":
                return activeServicePrice?.conservatory_gutter_clean_price

        }
    }

    function getServicePrice() {
        let servicePrice = servicePrices?.filter(servicePrice =>
            servicePrice.house_type.name === lead.houseType
        );
        if (servicePrice) {
            return servicePrice[0]
        }
    }

    useEffect(() => {
        if (location.state && location.state.lead != null) {
            setLead(location.state.lead)
        }
        if (location.state && location.state.selectedService != null) {
            setSelectedService(location.state.selectedService.toLowerCase())
            setServices([{name: location.state.selectedService}])
        } else {
            setSelectedService("gutter clean")
            setServices([{name: "gutter clean"}])
        }
        const getPriceTiers = async () => {
            let priceTiers = await GetServicePrices(lead.postcode);
            setServicePrices(priceTiers)
            setLoading(false)
        };
        getPriceTiers();
    }, [])
    useEffect(() => {
        if (lead.postcode == "") {
            return;
        }
        const handler = setTimeout(() => {
            setDebouncedPostcode(lead.postcode);
            const getAddresses = async () => {
                let addresses: AddressResponse = await GetAddressFromPostcode(lead.postcode) as AddressResponse;
                setAddressList(addresses.suggestions)

                if (addresses.suggestions.length > 0) {
                    setValidPostcode(true)
                } else {
                    setValidPostcode(false)
                }

            };
            getAddresses();


        }, 750);


        return () => {
            clearTimeout(handler);
        };

    }, [lead.postcode]);

    function saveBooking() {
        sendBookingConversion()
        saveForm()
        setBooked(true)
    }

    function saveForm() {
        SubmitBookingForm(lead, services, bookedIn, discountCode);
    }

    function sendBookingConversion() {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-16693850742/hq6cCOzeqNAZEPbkn5g-',
            'value': cost,
            'currency': 'GBP'
        });
    }


    const servicesContain = (name: string): boolean => {
        name = name.toLowerCase()
        return services.some(service => service.name === name);
    };
    const toggleService = (name: string) => {
        name = name.toLowerCase()
        setServices((prevServices) => {
            const exists = prevServices.some(service => service.name === name);
            if (exists) {
                return prevServices.filter(service => service.name !== name);
            } else {
                return [...prevServices, {name}];
            }
        });
    };

    function applyDiscountCode() {
        const validate = async () => {
            let discount = await ValidateDiscountCode(discountCode)
            if (Number.isInteger(Number(discount))) {
                setDiscount(Number(discount))
            } else {
                setDiscount(0)
            }
        };
        validate();
    }

    function getCostFromDiscount() {
        let initialCost = cost + (cost * 10) / 100
        if (discount == -1) {
            return cost;
        } else {
            return initialCost - (initialCost * discount) / 100;
        }
    }

    function getIncreasedDiscount() {
        return cost + (cost * 10) / 100;
    }

    return (<>

        <Box sx={(theme) => ({
            marginTop: '15px',
            display: 'flex',
            width: '1200px',
            height: '75px',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                width: 'auto',
            },

        })}>
            <NavigationBar selectedService={selectedService}/>

            {!loading ? (
                <>
                    {!booked ? (

                        <form style={{width: '100%'}} onSubmit={(event) => {
                            event.preventDefault();
                            saveBooking()
                        }}>

                            <Box sx={(theme) => ({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                width: '100%',
                                height: '770px',
                                marginTop: '50px',
                                [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column',
                                    height: 'auto',
                                },
                            })}>


                                <Sheet variant="plain" sx={(theme) => ({
                                    width: '55%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    [theme.breakpoints.down('md')]: {width: '90%', paddingTop: '15px'},
                                })}>
                                    <Box sx={(theme) => ({
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '20px',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        [theme.breakpoints.down('md')]: {margin: '0px'},
                                    })}>
                                        <Typography color="primary" level={"title-sm"}
                                                    sx={{marginBottom: '10px', marginTop: '10px'}}>Add
                                            Extra
                                            Services</Typography>

                                        <RadioGroup sx={(theme) => ({
                                            width: '75%',
                                            [theme.breakpoints.down('md')]: {width: '95%'},
                                        })} aria-label="Your plan" name="services" defaultValue="Individual">
                                            <List
                                                sx={{
                                                    width: '100%',
                                                    '--List-gap': '0.5rem',
                                                    '--ListItem-paddingY': '1rem',
                                                    '--ListItem-radius': '8px',
                                                    '--ListItemDecorator-size': '32px',
                                                }}
                                            >
                                                <ListItem variant="outlined" key={"Conservatory Gutter Clean"}
                                                          sx={{
                                                              boxShadow: 'sm',
                                                              minWidth: '100%',
                                                              background: 'white',
                                                              alignItems: 'normal',
                                                              flexDirection: 'column',

                                                          }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: '100%',
                                                        alignItems: 'normal'
                                                    }}>
                                                        <ListItemDecorator>
                                                            <Gite sx={{marginRight: '20px'}}/>
                                                        </ListItemDecorator>
                                                        <Radio
                                                            overlay
                                                            onClick={(event) => {
                                                                toggleService('Conservatory Gutter Clean')
                                                            }}
                                                            checked={servicesContain('Conservatory Gutter Clean')}
                                                            label={"Conservatory Gutter Clean " + formatAsGBP(activeServicePrice?.conservatory_gutter_clean_price)}

                                                            sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                            slotProps={{
                                                                action: ({checked}) => ({
                                                                    sx: (theme) => ({
                                                                        ...(checked && {
                                                                            inset: -1,
                                                                            border: '2px solid',
                                                                            borderColor: theme.vars.palette.primary[500],
                                                                        }),
                                                                    }),
                                                                }),
                                                            }}
                                                        />
                                                    </Box>
                                                </ListItem>
                                            </List>
                                        </RadioGroup>
                                        {selectedService == 'gutter clean' ? (

                                            <RadioGroup sx={(theme) => ({
                                                width: '75%',
                                                [theme.breakpoints.down('md')]: {width: '95%'},
                                            })}
                                                        name="services">
                                                <List
                                                    sx={{
                                                        width: '100%',
                                                        '--List-gap': '0.5rem',
                                                        '--ListItem-paddingY': '1rem',
                                                        '--ListItem-radius': '8px',
                                                        '--ListItemDecorator-size': '32px',
                                                    }}
                                                >
                                                    <ListItem variant="outlined" key={"Window Clean"}
                                                              sx={{
                                                                  boxShadow: 'sm',
                                                                  minWidth: '100%',
                                                                  background: 'white',
                                                                  alignItems: 'normal',
                                                                  flexDirection: 'column',

                                                              }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            width: '100%',
                                                            alignItems: 'normal'
                                                        }}>
                                                            <ListItemDecorator>
                                                                <Window sx={{marginRight: '20px'}}/>
                                                            </ListItemDecorator>
                                                            <Radio
                                                                overlay
                                                                onClick={(event) => {
                                                                    toggleService('Window Clean')
                                                                }}
                                                                checked={servicesContain('Window Clean')}
                                                                value={"Window Clean"}
                                                                label={"Window Clean " + formatAsGBP(activeServicePrice?.window_clean_price)}
                                                                sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                                slotProps={{
                                                                    action: ({checked}) => ({
                                                                        sx: (theme) => ({
                                                                            ...(checked && {
                                                                                inset: -1,
                                                                                border: '2px solid',
                                                                                borderColor: theme.vars.palette.primary[500],
                                                                            }),
                                                                        }),
                                                                    }),
                                                                }}
                                                            />
                                                        </Box>
                                                    </ListItem>
                                                </List>
                                            </RadioGroup>

                                        ) : (
                                            <RadioGroup sx={(theme) => ({
                                                width: '75%',
                                                [theme.breakpoints.down('md')]: {width: '95%'},
                                            })}>
                                                <List
                                                    sx={{
                                                        width: '100%',
                                                        '--List-gap': '0.5rem',
                                                        '--ListItem-paddingY': '1rem',
                                                        '--ListItem-radius': '8px',
                                                        '--ListItemDecorator-size': '32px',
                                                    }}
                                                >
                                                    <ListItem variant="outlined" key={"Gutter Clean"}
                                                              sx={{
                                                                  boxShadow: 'sm',
                                                                  minWidth: '100%',
                                                                  background: 'white',
                                                                  alignItems: 'normal',
                                                                  flexDirection: 'column',

                                                              }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            width: '100%',
                                                            alignItems: 'normal'
                                                        }}>
                                                            <ListItemDecorator>
                                                                <Roofing sx={{marginRight: '20px'}}/>
                                                            </ListItemDecorator>
                                                            <Radio
                                                                overlay
                                                                onClick={(event) => {
                                                                    toggleService('Gutter Clean')
                                                                }}
                                                                checked={servicesContain('Gutter Clean')}
                                                                value={"Gutter Clean"}
                                                                label={"Gutter Clean " + formatAsGBP(activeServicePrice?.gutter_clean_price)}
                                                                sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                                slotProps={{
                                                                    action: ({checked}) => ({
                                                                        sx: (theme) => ({
                                                                            ...(checked && {
                                                                                inset: -1,
                                                                                border: '2px solid',
                                                                                borderColor: theme.vars.palette.primary[500],
                                                                            }),
                                                                        }),
                                                                    }),
                                                                }}
                                                            />
                                                        </Box>
                                                    </ListItem>
                                                </List>
                                            </RadioGroup>
                                        )}


                                        <Typography color="primary" level={"title-sm"}
                                                    sx={{marginBottom: '10px', marginTop: '10px'}}>Booking
                                            Day</Typography>
                                        <Box sx={(theme) => ({
                                            width: '75%', marginBottom: '10px',
                                            [theme.breakpoints.down('md')]: {width: '95%'}
                                        })}>
                                            <DatePicker
                                                selected={bookedIn}
                                                filterDate={isWeekdayAndInPast}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => {
                                                    setBookedIn(date);
                                                }}
                                                customInput={<DateCustomInput/>}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={(theme) => ({
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '20px',
                                        marginTop: '0px',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        [theme.breakpoints.down('md')]: {margin: '0px'},
                                    })}>
                                        <Typography color="primary" level={"title-sm"}
                                                    sx={{marginBottom: '10px', marginTop: '10px'}}>Discount
                                            Code</Typography>

                                        <Box sx={(theme) => ({
                                            width: '75%', marginBottom: '10px',
                                            [theme.breakpoints.down('md')]: {width: '95%'}
                                        })}>
                                            <FormLabel>Discount Count</FormLabel>
                                            <Input value={discountCode}
                                                   onChange={(event) => {
                                                       setDiscountCode(event.target.value)
                                                   }} sx={{background: 'white'}}
                                                   endDecorator={<Button size={"sm"} onClick={() => applyDiscountCode()}
                                                                         variant="solid">Apply</Button>}></Input>

                                            {discount == 0 &&
                                                <FormHelperText>
                                                    <Typography color="danger">Invalid Discount Code!</Typography>
                                                </FormHelperText>
                                            }

                                            {discount > 0 &&
                                                <FormHelperText>
                                                    <Typography color="success">+{discount}% discount
                                                        applied</Typography>
                                                </FormHelperText>
                                            }
                                        </Box>
                                    </Box>


                                    <Box sx={(theme) => ({
                                        order: noLead ? -1 : 10,
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '20px',
                                        marginBottom: noLead ? '0px' : '20px',
                                        [theme.breakpoints.down('md')]: {margin: '0'}

                                    })}>
                                        <AccordionGroup sx={(theme) => ({
                                            margin: '10px',
                                            padding: 0,
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            [theme.breakpoints.down('md')]: {width: '90%'}
                                        })}>
                                            <Accordion defaultExpanded={lead.name == ''} sx={(theme) => ({
                                                width: '75%',
                                                padding: 0,
                                                [theme.breakpoints.down('md')]: {width: '95%'}
                                            })}>
                                                <AccordionSummary><Typography align="center" sx={{width: '100%'}}
                                                                              color="primary" level={"title-sm"}>Your
                                                    Information</Typography></AccordionSummary>
                                                <AccordionDetails>
                                                    <Box id={"booking-form"} color="neutral" sx={(theme) => ({
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        [theme.breakpoints.down('md')]: {width: '100%'},
                                                    })}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            maxWidth: '100%'
                                                        }}>
                                                            <Box sx={{
                                                                minWidth: '100%',
                                                                maxWidth: '100%',
                                                                marginBottom: '10px'
                                                            }}>
                                                                <FormLabel>Full Name</FormLabel>
                                                                <Input startDecorator={<Person/>}
                                                                       value={lead.name}
                                                                       required onChange={(event) => {
                                                                    setLead(prevLead => ({
                                                                        ...prevLead,
                                                                        name: event.target.value,
                                                                    }))
                                                                }}
                                                                       sx={{width: '100%', background: 'white'}}
                                                                       placeholder="Full Name"></Input>
                                                            </Box>
                                                            <Box sx={(theme) => ({
                                                                display: 'flex',
                                                                width: '100%',
                                                                justifyContent: 'space-between',
                                                                marginTop: '10px',
                                                                marginBottom: '10px',

                                                                [theme.breakpoints.down('md')]: {
                                                                    flexDirection: 'column'
                                                                }
                                                            })}>
                                                                <Box sx={(theme) => ({
                                                                    minWidth: '49%', maxWidth: '49%',
                                                                    [theme.breakpoints.down('md')]: {
                                                                        minWidth: '100%', maxWidth: '100%',
                                                                    }
                                                                })}>
                                                                    <FormLabel>E-Mail Address</FormLabel>
                                                                    <Input required startDecorator={<Email/>}
                                                                           onChange={(event) => {
                                                                               setLead(prevLead => ({
                                                                                   ...prevLead,
                                                                                   eMail: event.target.value,
                                                                               }));

                                                                           }} value={lead.eMail}
                                                                           sx={{background: 'white'}}
                                                                           placeholder="Email"></Input>
                                                                </Box>
                                                                <Box sx={(theme) => ({
                                                                    minWidth: '49%', maxWidth: '49%',
                                                                    [theme.breakpoints.down('md')]: {
                                                                        minWidth: '100%', maxWidth: '100%',
                                                                        marginTop: '20px'
                                                                    }
                                                                })}>
                                                                    <FormLabel>Phone Number</FormLabel>

                                                                    <Input startDecorator={<Phone/>}
                                                                           onChange={(event) => {
                                                                               setLead(prevLead => ({
                                                                                   ...prevLead,
                                                                                   phone: event.target.value,
                                                                               }))
                                                                           }} value={lead.phone}
                                                                           sx={{background: 'white'}}
                                                                           placeholder="Phone Number"></Input>
                                                                </Box>
                                                            </Box>
                                                            <Box sx={(theme) => ({
                                                                display: 'flex',
                                                                width: '100%',
                                                                justifyContent: 'space-between',
                                                                marginTop: '10px',
                                                                marginBottom: '10px',
                                                                [theme.breakpoints.down('md')]: {
                                                                    flexDirection: 'column'
                                                                }

                                                            })}>
                                                                <Box sx={(theme) => ({
                                                                    minWidth: '49%', maxWidth: '49%',
                                                                    [theme.breakpoints.down('md')]: {
                                                                        minWidth: '100%', maxWidth: '100%',
                                                                    }
                                                                })}>
                                                                    <FormLabel>House Type</FormLabel>
                                                                    <Select
                                                                        required
                                                                        value={lead.houseType}
                                                                        sx={{background: 'white', minWidth: '100%'}}
                                                                        startDecorator={<Home/>}
                                                                        onChange={(e, newValue) => {
                                                                            setLead(prevLead => ({
                                                                                ...prevLead,
                                                                                houseType: newValue,
                                                                            }))
                                                                        }}
                                                                        defaultValue={"Detached"}
                                                                    >
                                                                        <Option value={"Detached"}>Detached</Option>
                                                                        <Option
                                                                            value={"Semi Detached"}>Semi-Detached</Option>
                                                                        <Option value={"Terrace"}>Terrace</Option>
                                                                        <Option value={"Bungalow"}>Bungalow</Option>
                                                                        <Option value={"Ground Floor Apartment"}>Ground
                                                                            Floor
                                                                            Apartment</Option>
                                                                        <Option value={"2nd Floor Apartment"}>2nd Floor
                                                                            Apartment</Option>
                                                                        <Option value={"3rd Floor Apartment"}>3rd Floor
                                                                            Apartment</Option>
                                                                    </Select>
                                                                </Box>
                                                                <Box sx={(theme) => ({
                                                                    minWidth: '49%', maxWidth: '49%',
                                                                    [theme.breakpoints.down('md')]: {
                                                                        minWidth: '100%',
                                                                        maxWidth: '100%',
                                                                        marginTop: '20px'
                                                                    }
                                                                })}>
                                                                    <FormLabel>Postcode</FormLabel>
                                                                    <Input required value={lead.postcode}
                                                                           inputProps={{style: {textTransform: "uppercase"}}}
                                                                           onChange={(event) => {
                                                                               setLead(prevLead => ({
                                                                                   ...prevLead,
                                                                                   postcode: event.target.value.toUpperCase()
                                                                               }));
                                                                           }} value={lead.postcode}
                                                                           sx={{background: 'white'}}
                                                                           placeholder="Postcode"></Input>

                                                                    {!validPostcode &&
                                                                        <FormHelperText>
                                                                            <Typography nowrap color="danger">Please
                                                                                enter a
                                                                                valid
                                                                                postcode!</Typography>
                                                                        </FormHelperText>
                                                                    }

                                                                </Box>
                                                            </Box>

                                                            <Tooltip
                                                                title={addressList?.length > 0 ? "" : "Please enter a valid postcode!"}
                                                                arrow placement="bottom" variant="solid"
                                                                color="danger">
                                                                <Box sx={{
                                                                    width: '100%',
                                                                    marginTop: '10px',
                                                                    marginBottom: '10px'
                                                                }}>
                                                                    <FormLabel>Address</FormLabel>
                                                                    <Select
                                                                        disabled={addressList?.length == 0}
                                                                        required
                                                                        value={lead.address}
                                                                        slotProps={{
                                                                            listbox: {
                                                                                sx: {
                                                                                    maxWidth: '600px',
                                                                                    minWidth: '600px',
                                                                                    "> li:nth-child(odd)": {
                                                                                        background: "rgba(240,244,248,0.6)",
                                                                                    },
                                                                                },
                                                                            },
                                                                        }}
                                                                        onChange={(e, newValue) => {
                                                                            setLead(prevLead => ({
                                                                                ...prevLead,
                                                                                address: newValue,
                                                                            }))
                                                                        }}
                                                                        placeholder="Select address..."
                                                                        sx={{background: 'white'}}
                                                                    >
                                                                        {addressList?.map((address: Address, index: number) => (
                                                                            <Option
                                                                                value={address.address}>{address.address}</Option>
                                                                        ))}

                                                                    </Select>
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>

                                                    </Box>

                                                </AccordionDetails>
                                            </Accordion>
                                        </AccordionGroup>
                                    </Box>
                                </Sheet>
                                <Divider orientation="vertical" sx={(theme) => ({
                                    height: '100%', width: '3px',
                                    [theme.breakpoints.down('md')]: {display: 'none'},
                                })}/>


                                <Divider sx={(theme) => ({
                                    height: '3px',
                                    width: '90%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: '15px',
                                    [theme.breakpoints.up('md')]: {display: 'none'},
                                })}/>
                                <Sheet variant="plain" sx={(theme) => ({
                                    width: '45%', height: '100%',
                                    [theme.breakpoints.down('md')]: {width: '90%'},
                                })}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '20px',
                                        maxWidth: '60%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        height: '100%'
                                    }}>

                                        <Typography level={"title-lg"}>Your Quote</Typography>
                                        <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                                            <Typography fontWeight="500 "
                                                        level={"h1"}>{formatAsGBP(getCostFromDiscount())}</Typography>
                                            <Typography sx={{textDecoration: 'line-through', marginLeft: '5px'}}
                                                        level={"title-sm"}>{formatAsGBP(getIncreasedDiscount())}</Typography>

                                        </Box>


                                        <Box sx={{height: '185px', width: '100%'}}>
                                            {services?.map((service: Service, index: number) => (
                                                <>
                                                    <Box sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        marginTop: '15px'
                                                    }}>
                                                        <Typography
                                                            level={"title-sm"}>{service.name.replace(/\b\w/g, char => char.toUpperCase())}</Typography>
                                                        <Typography
                                                            level={"title-sm"}>{formatAsGBP(getServicePriceByService(service.name))}</Typography>
                                                    </Box>
                                                    <Divider sx={{height: '4px'}}/>
                                                </>

                                            ))}
                                            {discount > 0 ? (

                                                <>
                                                    <Box sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        marginTop: '10px'
                                                    }}>
                                                        <Typography level={"title-sm"} color={"success"}>Discount
                                                            Code: {discountCode.toUpperCase()}</Typography>
                                                        <Typography level={"title-sm"} color={"success"}>{discount}%
                                                            OFF</Typography>
                                                    </Box>
                                                    <Divider sx={{height: '4px'}}/>
                                                </>

                                            ) : (
                                                <>
                                                    <Box sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        marginTop: '10px'
                                                    }}>
                                                        <Typography level={"title-sm"} color={"success"}>Base
                                                            Discount</Typography>
                                                        <Typography level={"title-sm"} color={"success"}>10%
                                                            OFF</Typography>
                                                    </Box>
                                                    <Divider sx={{height: '4px'}}/>
                                                </>
                                            )}

                                            {services.some(service => service.name === 'gutter clean') && (
                                                <>

                                                    <Box sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        marginTop: '10px'
                                                    }}>
                                                        <Typography level={"title-sm"}>6 Month Certified
                                                            Gutters</Typography>
                                                        <Typography level={"title-sm"}>£0</Typography>
                                                    </Box>
                                                    <Divider sx={{height: '4px'}}/>
                                                </>
                                            )}
                                            <Box sx={{
                                                width: '100%',
                                                display: 'flex',
                                                marginTop: '10px',
                                                alignItems: 'center'
                                            }}>
                                                <CalendarMonth sx={{marginRight: '5px'}}/>
                                                <Typography level={"title-sm"}>Cleaning on <Typography fontWeight={750}
                                                                                                       level={"title-sm"}>{formatBookedInDate(bookedIn)}</Typography></Typography>

                                            </Box>
                                        </Box>


                                        <Box sx={(theme) => ({
                                            marginTop: '100px',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            [theme.breakpoints.down('md')]: {marginTop: '75px'}

                                        })}>
                                            <Typography level={"title-sm"}>You <Typography level={"title-sm"}
                                                                                           fontWeight={750}>don't
                                                pay
                                                a
                                                penny</Typography> until you're</Typography>
                                            <Typography level={"title-sm"}>100% satisfied with the service.</Typography>
                                            <Button type="submit" size={"lg"}
                                                    sx={{width: '100%', marginTop: '15px', marginBottom: '15px'}}>BOOK
                                                NOW</Button>

                                            <Box sx={(theme) => ({
                                                display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                                alignItems: 'center',
                                                [theme.breakpoints.down('md')]: {
                                                    flexDirection: 'column',
                                                    height: '112px'
                                                }
                                            })}>
                                                <img style={{height: '50px'}} src={trust_pilot}
                                                     alt="Sky High Window Cleaning trust pilot reviews"/>
                                                <img style={{height: '50px'}} src={badges}
                                                     alt="Sky High Window Cleaning badgest and certificate"/>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Sheet>
                            </Box>
                        </form>

                    ) : (

                        <Box sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            width: '100%',
                            marginTop: '50px',
                            [theme.breakpoints.down('md')]: {
                                textAlign: 'center'
                            }
                        })}>

                            <Sheet variant="plain" sx={{
                                width: '55%',
                                flexDirection: 'column',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography level={"h2"} color={"success"} endDecorator={<EventAvailable/>}>Booking
                                    Confirmed </Typography>

                                <Typography level={"title-sm"} color={"neutral"}>Thank you for confirming your
                                    booking,</Typography>
                                <Typography level={"title-sm"} color={"neutral"}>we will be with you on <Typography
                                    level={"title-sm"}
                                    color={"neutral"}
                                    fontWeight="750">{formatBookedInDate(bookedIn)}</Typography></Typography>

                                <Divider sx={{marginBottom: '15px'}}/>
                                <Typography color={"success"} level={"title-sm"}>You will
                                    receive a SMS and Email
                                    confirmation shortly.</Typography>

                                <Typography color={"neutral"} level={"body-xs"} sx={{marginBottom: '15px'}}>You can now
                                    close this
                                    window.</Typography>
                            </Sheet>

                        </Box>

                    )}

                </>


            ) : (
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginTop: '25%',
                    justifyContent: 'center'
                }}>
                    <CircularProgress
                        size="lg"
                    />
                </Box>
            )}
        </Box>

    </>)

}

